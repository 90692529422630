import React from "react"
import Layout from "../components/Layout"
import Skills from "../components/Skills"
import AllProjects from "../components/AllProjects"
import ClientFeedback from "../components/ClientFeedback"
import SEOmeta from "../components/SEOmeta"

const IndexPage = () => {
  return (
    <Layout>
      <SEOmeta title="Home" />
      <main className="page">
        <header className="hero">
          <div className="hero-container">
            <div className="hero-text">
              <h1 className="drop-bounce-text">
                <span>Shaun Vine</span>
              </h1>
              <h2>Web Content Engineer</h2>
              <p className="hire">Need a Web Content Engineer?</p>
              <p>
                I bring expertise in content management systems, SEO, and web
                development to optimize and maintain your digital presence.
              </p>
            </div>
          </div>
        </header>
        <Skills />
        <ClientFeedback />
        <AllProjects />
      </main>
    </Layout>
  )
}

export default IndexPage
